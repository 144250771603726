<script>
import { Table } from 'ant-design-vue'
import api from '@/command/api'
import { postAction, getAction } from '@/command/netTool'
import TableView from '@/components/TableVIew'

export default {
  props: {
    productId: {
      type: String,
      default: ''
    },
    productIdArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...api.command.getState()
    }
  },
  computed: {},
  mounted() {
    if (this.productId) {
      this.getInitData()
    }
  },
  methods: {
    getInitData() {
      getAction('/commodity/farmNativeProductStandards/list', { productId: this.productId }, '/api').then(res => {
        if (res.code == 200) {
          this.records = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    sumbit() {
      let data = this.$refs.table.selectedRows
      this.$emit('success', data)
    },
    getColumns() {
      return [
        {
          dataIndex: 'standardsName',
          title: '规格名',
          align: 'left'
        },
        {
          dataIndex: 'salePrice',
          title: '价格',
          align: 'left'
        },
        {
          dataIndex: 'salePrice',
          title: '市场价',
          align: 'left'
        },
        {
          dataIndex: 'stockNum',
          title: '库存',
          align: 'left'
        }
        // {
        //   dataIndex: 'a1',
        //   title: '计价单位',
        //   align: 'left'
        // }
      ]
    }
  },
  render() {
    return (
      <div>
        <TableView
          showPagination={false}
          showRowSelect={true}
          pagination={false}
          columns={this.getColumns()}
          dataSource={this.records}
          ref="table"
          selectedRowKeys={this.productIdArr}
        />
      </div>
    )
  }
}
</script>
