<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'
import { Input } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import tableData from './tableData'

export default {
  name: 'aBulkDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        tableDataProduct: []
      },
      mainBodyType: [],
      productId: ''
    }
  },
  watch: {},
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/peopleTeam/info/${id}`
        })
        .then(res => {
          this.productId = res.product.id

          this.detail = {
            ...res,
            goodsId: res.product.id,
            goodsName: res.product.nativeProductName,
            tableDataProduct: res.teamDetailVOList.map(e => {
              return {
                ...e,
                standardsName: e.standards.standardsName,
                salePrice: e.standards.salePrice,
                stockNum: e.standards.stockNum
              }
            })
          }
        })
    }
  },
  methods: {
    onProductMenuClick(data) {
      this.productId = data.value
      this.detail.tableDataProduct = []
      this.$forceUpdate()
    },
    async onMainBodySearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/page?nativeProductName=${value}`
      })
      this.mainBodyType = searchResult.records.map(el => {
        return {
          name: el.nativeProductName,
          value: el.id
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '团购名称',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 12
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '选择商品',
            type: 'select',
            cols: 6,
            key: 'goodsType',
            typeData: [
              {
                name: '农家特产',
                value: '0'
              }
            ]
          },
          {
            name: '关联商品',
            type: 'dropDownInput',
            cols: 6,
            typeData: this.mainBodyType,
            key: 'goodsName',
            valueKey: 'goodsId',
            onInputSearch: this.onMainBodySearch,
            onMenuClick: this.onProductMenuClick,
            inputProps: {
              addonBefore: <a-icon type="search" />
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '开始时间',
            type: 'datePicker',
            cols: 6,
            key: 'startDate',
            props: {
              format: 'YYYY-MM-DD HH:mm',
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
              placeholder: '请选择开始时间',
              showTime: true
            },
            rules: [
              {
                required: true,
                type: 'date'
              }
            ]
          },
          {
            name: '结束时间',
            type: 'datePicker',
            cols: 6,
            key: 'endDate',
            props: {
              format: 'YYYY-MM-DD HH:mm',
              valueFormat: 'YYYY-MM-DD HH:mm:ss',
              placeholder: '请选择结束时间',
              showTime: true
            },
            rules: [
              {
                required: true,
                type: 'date'
              }
            ]
          },
          {
            name: '排序',
            type: 'inputNumber',
            key: 'sort',
            cols: 6,
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        title: '成员信息',
        type: 'cardForm',
        data: [
          {
            name: '拼团时效（小时）',
            type: 'inputNumber',
            cols: 12,
            key: 'aging',
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '成团方式',
            type: 'select',
            cols: 6,
            key: 'succeedStatus',
            typeData: [
              {
                name: '按购买人数',
                value: '0'
              },
              {
                name: '按购买份数',
                value: '1'
              }
            ]
            // rules: [
            //   {
            //     required: true,
            //     type: 'string'
            //   }
            // ]
          },
          ...[
            {
              name: '成团人数（人数）',
              type: 'inputNumber',
              cols: 6,
              key: 'succeedNum',
              display: this.detail.succeedStatus == '0',
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            },
            {
              name: '成团份数（份数）',
              type: 'inputNumber',
              cols: 6,
              key: 'succeedNum',
              display: this.detail.succeedStatus == '1',
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            }
          ].filter(e => e.display)
        ]
      }
    },
    getForm3() {
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddCustom: () => {
          this.handlePopup()
        },
        columns: [
          {
            dataIndex: 'standardsName',
            align: 'left',
            width: 200,
            title: <div>规格名称</div>
          },
          {
            dataIndex: 'salePrice',
            align: 'left',
            width: 200,
            title: <div>原价</div>,
            customRender: function(text, records) {
              return <div>￥{text}</div>
            }
          },
          {
            dataIndex: 'teamPrice',
            align: 'left',
            width: 200,
            title: <div>团购价</div>,
            customRender: function(text, records) {
              const onInput = data => {
                records.teamPrice = data.target.value
              }
              return records.isEdit ? <Input onInput={onInput} value={text} /> : <div>{text}</div>
            }
          },
          {
            dataIndex: 'teamOwnerPrice',
            align: 'left',
            width: 200,
            title: <div>团长价（等于或低于团购价）</div>,
            customRender: function(text, records) {
              const onInput = data => {
                records.teamOwnerPrice = data.target.value
              }
              return records.isEdit ? <Input onInput={onInput} value={text} /> : <div>{text}</div>
            }
          },
          {
            dataIndex: 'stockNum',
            align: 'left',
            width: 200,
            title: <div>库存</div>
          },
          {
            dataIndex: 'teamStock',
            align: 'left',
            width: 200,
            title: <div>团购库存（从商品库存中扣除）</div>,
            customRender: function(text, records) {
              const onInput = data => {
                records.teamStock = data.target.value
              }
              return records.isEdit ? <Input onInput={onInput} value={text} /> : <div>{text}</div>
            }
          },
          {
            dataIndex: 'a3',
            align: 'center',
            width: 200,
            title: '操作',
            tableType: 'editDelete',
            onSave: (text, records) => {
              if (Number(records.teamOwnerPrice) > Number(records.teamPrice)) {
                this.$message.warning('团长价（等于或低于团购价）')
                return
              } else {
                records.isEdit = false
              }
            }
          }
        ],
        dataSource: this.detail.tableDataProduct
      }
    },
    handlePopup() {
      apiTool.showDrawer({
        title: '选择规格',
        width: '600px',
        view: tableData,
        viewProps: {
          productId: this.productId,
          productIdArr: this.detail.tableDataProduct.map(e => e.goodsSkuId)
        },
        success: ({ data, setHidden }) => {
          let productIdArr = this.detail.tableDataProduct.map(e => e.goodsSkuId)
          try {
            data.forEach(e => {
              if (productIdArr.find(f => f == e.id)) {
                this.$message.warning('数据已存在！')
                throw new Error('结束！')
              }
            })
          } catch (error) {
            console.log(error)
            return
          }

          let dataT = data.map(e => {
            return {
              goodsId: e.nativeProductId,
              goodsSkuId: e.id,
              teamOwnerPrice: '',
              teamPrice: '',
              teamStock: '',
              standardsName: e.standardsName,
              salePrice: e.salePrice,
              stockNum: e.stockNum,
              isEdit: true
            }
          })
          this.detail.tableDataProduct = this.detail.tableDataProduct.concat(dataT)
          this.$forceUpdate()
          setHidden()
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            try {
              data.tableDataProduct.forEach(element => {
                if (element.isEdit) {
                  this.$message.warning('请先保存规格名称！')
                  throw new Error('结束！')
                }
              })
            } catch (error) {
              console.log(error)
              return
            }
            api.command[data.id ? 'editPost' : 'create']
              .call(this, {
                url: `/peopleTeam/${data.id ? 'edit' : 'save'}`,
                params: {
                  ...data,
                  teamDetailDTOList: data.tableDataProduct
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/marketingCenter/aBulk')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/aBulk')
        }
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/peopleTeam/delete/${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/aBulk')
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
